import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Card, Statistic, Form, Input, InputNumber, Select, Button, message } from 'antd';
import { CarOutlined, DollarOutlined, TagOutlined } from '@ant-design/icons';
import { fetchDashboardStats, createVehicle } from '../store/slices/vehicleSlice';
import { fetchLocations } from '../store/slices/locationSlice';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const { Option } = Select;

const DashboardWithAddVehicle = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { totalCars, carsForSale, soldCars } = useSelector(state => state.vehicles.dashboardStats);
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    dispatch(fetchDashboardStats());
    dispatch(fetchLocations());
  }, [dispatch]);

  const onFinish = async (values) => {
    try {
      setIsCreating(true);
      const result = await dispatch(createVehicle(values)).unwrap();
      message.success('Vehicle added successfully');
      form.resetFields();
      dispatch(fetchDashboardStats());
      navigate(`/vehicles/${result.id}`);
    } catch (error) {
      message.error('Failed to add vehicle: ' + error.message);
    } finally {
      setIsCreating(false);
    }
  };

  const chartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Sales',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Monthly Sales',
      },
    },
  };

  return (
    <div style={{ padding: '20px', backgroundColor: '#f0f2f5' }}>
      <h1>Vehicle Management</h1>
      <Card title="New Vehicle Model" style={{ marginBottom: '20px' }}>
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="registrationNumber"
                label="Registration Number"
                rules={[{ required: true, message: 'Please input the registration number' }]}
              >
                <Input placeholder="Enter registration number" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="mileage"
                label="Mileage"
                rules={[{ required: true, message: 'Please input the mileage' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/,/g, '')}
                  addonAfter="km"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="vehicleType"
                label="Vehicle Type"
                rules={[{ required: true, message: 'Please select the vehicle type' }]}
              >
                <Select defaultValue="Car">
                  <Option value="Car">Car</Option>
                  <Option value="Motorcycle">Motorcycle</Option>
                  <Option value="Van">Van</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={isCreating} style={{ marginRight: '10px' }}>
                  Create Vehicle Model
                </Button>
                <Button onClick={() => form.resetFields()}>
                  Clear Form
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>

      <Row gutter={16} style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <Card>
            <Statistic title="Total Cars" value={totalCars} prefix={<CarOutlined />} />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic title="Cars for Sale" value={carsForSale} prefix={<TagOutlined />} />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic title="Sold Cars" value={soldCars} prefix={<DollarOutlined />} />
          </Card>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Card title="Sales Chart">
            <Bar data={chartData} options={chartOptions} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DashboardWithAddVehicle;