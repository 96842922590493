import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';

export const fetchMakes = createAsyncThunk(
  'vehicleData/fetchMakes',
  async () => {
    const response = await axiosInstance.get('/api/vehicle-data/makes');
    return response.data;
  }
);

export const fetchModels = createAsyncThunk(
  'vehicleData/fetchModels',
  async (make) => {
    const response = await axiosInstance.get(`/api/vehicle-data/models?make=${make}`);
    return response.data;
  }
);

const vehicleDataSlice = createSlice({
  name: 'vehicleData',
  initialState: {
    makes: [],
    models: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMakes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMakes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.makes = action.payload;
      })
      .addCase(fetchMakes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchModels.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchModels.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.models = action.payload;
      })
      .addCase(fetchModels.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default vehicleDataSlice.reducer;