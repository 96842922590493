import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';

export const fetchLocations = createAsyncThunk(
  'locations/fetchLocations',
  async () => {
    const response = await axiosInstance.get(`/api/locations`);
    return response.data;
  }
);

export const addLocation = createAsyncThunk(
  'locations/addLocation',
  async (locationData) => {
    const response = await axiosInstance.post('/api/locations', locationData);
    return response.data;
  }
);

const locationSlice = createSlice({
  name: 'locations',
  initialState: {
    list: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocations.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.list = action.payload;
      })
      .addCase(fetchLocations.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addLocation.fulfilled, (state, action) => {
        state.list.push(action.payload);
      });
  },
});

export default locationSlice.reducer;