import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Modal, Form, Input, Select, message } from 'antd';
import { fetchCampaigns, createCampaign } from '../store/slices/campaignSlice';

const { Option } = Select;

const CampaignManagement = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  const campaigns = useSelector(state => state.campaigns.list);

  useEffect(() => {
    dispatch(fetchCampaigns());
  }, [dispatch]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const onFinish = async (values) => {
    try {
      await dispatch(createCampaign(values)).unwrap();
      message.success('Campaign created successfully');
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      message.error('Failed to create campaign');
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button onClick={() => console.log('Edit campaign', record.id)}>Edit</Button>
      ),
    },
  ];

  return (
    <div>
      <h1>Campaign Management</h1>
      <Button onClick={showModal} type="primary" style={{ marginBottom: 16 }}>
        Create New Campaign
      </Button>
      <Table columns={columns} dataSource={campaigns} rowKey="id" />
      <Modal
        title="Create New Campaign"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item name="name" label="Campaign Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="status" label="Status" rules={[{ required: true }]}>
            <Select>
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CampaignManagement;