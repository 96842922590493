import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Input, Select, Button, Tag, Row, Col } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { fetchVehicles } from '../store/slices/vehicleSlice';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

const VehicleManagement = () => {
  const dispatch = useDispatch();
  const vehicles = useSelector(state => state.vehicles.list);
  const loading = useSelector(state => state.vehicles.status === 'loading');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [filters, setFilters] = useState({
    status: '',
    search: '',
  });
  const [sortedInfo, setSortedInfo] = useState({});

  useEffect(() => {
    dispatch(fetchVehicles());
  }, [dispatch]);

  useEffect(() => {
    let filtered = vehicles.filter(vehicle => {
      return (
        (filters.status === '' || vehicle.status === filters.status) &&
        (filters.search === '' || 
          vehicle.make.toLowerCase().includes(filters.search.toLowerCase()) ||
          vehicle.model.toLowerCase().includes(filters.search.toLowerCase()) ||
          vehicle.registrationNumber.toLowerCase().includes(filters.search.toLowerCase()))
      );
    });

    // Apply multi-column sorting
    if (sortedInfo.columnKey && sortedInfo.order) {
      filtered = filtered.sort((a, b) => {
        let result = 0;
        sortedInfo.columnKey.forEach((key, index) => {
          const order = sortedInfo.order[index] === 'ascend' ? 1 : -1;
          if (result === 0) {
            if (typeof a[key] === 'string') {
              result = order * a[key].localeCompare(b[key]);
            } else {
              result = order * (a[key] - b[key]);
            }
          }
        });
        return result;
      });
    }

    setFilteredVehicles(filtered);
  }, [vehicles, filters, sortedInfo]);

  const handleFilterChange = (key, value) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleChange = (pagination, filters, sorter) => {
    if (Array.isArray(sorter)) {
      // Multi-column sort
      setSortedInfo({
        columnKey: sorter.map(s => s.columnKey),
        order: sorter.map(s => s.order),
      });
    } else {
      // Single column sort
      setSortedInfo({
        columnKey: [sorter.columnKey],
        order: [sorter.order],
      });
    }
  };

  const navigate = useNavigate();

  const handleRowClick = (record) => {
    navigate(`/vehicles/${record.id}`);
  };

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      sortOrder: sortedInfo.columnKey?.includes('status') && sortedInfo.order[sortedInfo.columnKey.indexOf('status')],
      render: (status) => (
        <Tag color={status === 'for_sale' ? 'green' : 'volcano'}>
          {status === 'for_sale' ? 'MYYNNISSÄ' : 'MYYTY'}
        </Tag>
      ),
    },
    {
      title: 'Registration Number',
      dataIndex: 'registrationNumber',
      key: 'registrationNumber',
      sorter: true,
      sortOrder: sortedInfo.columnKey?.includes('registrationNumber') && sortedInfo.order[sortedInfo.columnKey.indexOf('registrationNumber')],
    },
    {
      title: 'Make',
      dataIndex: 'make',
      key: 'make',
      sorter: true,
      sortOrder: sortedInfo.columnKey?.includes('make') && sortedInfo.order[sortedInfo.columnKey.indexOf('make')],
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
      sorter: true,
      sortOrder: sortedInfo.columnKey?.includes('model') && sortedInfo.order[sortedInfo.columnKey.indexOf('model')],
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      sorter: true,
      sortOrder: sortedInfo.columnKey?.includes('year') && sortedInfo.order[sortedInfo.columnKey.indexOf('year')],
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      sorter: true,
      sortOrder: sortedInfo.columnKey?.includes('location') && sortedInfo.order[sortedInfo.columnKey.indexOf('location')],
    },
    {
      title: 'Mileage',
      dataIndex: 'mileage',
      key: 'mileage',
      sorter: true,
      sortOrder: sortedInfo.columnKey?.includes('mileage') && sortedInfo.order[sortedInfo.columnKey.indexOf('mileage')],
      render: (mileage) => `${mileage} km`,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      sorter: true,
      sortOrder: sortedInfo.columnKey?.includes('price') && sortedInfo.order[sortedInfo.columnKey.indexOf('price')],
      render: (price) => `${price} €`,
    },
    {
      title: 'MPV',
      dataIndex: 'mpv',
      key: 'mpv',
      sorter: true,
      sortOrder: sortedInfo.columnKey?.includes('mpv') && sortedInfo.order[sortedInfo.columnKey.indexOf('mpv')],
      render: (mpv) => `${mpv} €`,
    },
  ];

  return (
    <div style={{ padding: '20px' }}>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col>
          <Select
            style={{ width: 200 }}
            placeholder="Status"
            onChange={(value) => handleFilterChange('status', value)}
          >
            <Option value="">All</Option>
            <Option value="for_sale">MYYNNISSÄ</Option>
            <Option value="sold">MYYTY</Option>
          </Select>
        </Col>
        <Col>
          <Input
            style={{ width: 200 }}
            placeholder="Search"
            prefix={<SearchOutlined />}
            onChange={(e) => handleFilterChange('search', e.target.value)}
          />
        </Col>
        <Col>
          <Button type="primary" icon={<PlusOutlined />}>
            Add Vehicle
          </Button>
        </Col>
      </Row>
      <div style={{ marginBottom: 16 }}>
        <span style={{ marginLeft: 8 }}>
          {`Selected ${selectedRowKeys.length} items`}
        </span>
      </div>
      <Table 
        rowSelection={rowSelection}
        columns={columns} 
        dataSource={filteredVehicles} 
        rowKey="id" 
        loading={loading}
        onChange={handleChange}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
          style: { cursor: 'pointer' }
        })}
      />
    </div>
  );
};

export default VehicleManagement;