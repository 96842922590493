import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Modal, Form, Input, message } from 'antd';
import { fetchLocations, addLocation } from '../store/slices/locationSlice';

const LocationManagement = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  const locations = useSelector(state => state.locations.list);
  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    dispatch(fetchLocations());
  }, [dispatch]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const onFinish = async (values) => {
    try {
      await dispatch(addLocation(values)).unwrap();
      message.success('Location added successfully');
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      message.error('Failed to add location');
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
  ];

  if (user?.role !== 'admin') {
    return <h2>You don't have permission to access this page.</h2>;
  }

  return (
    <div>
      <h1>Location Management</h1>
      <Button onClick={showModal} type="primary" style={{ marginBottom: 16 }}>
        Add New Location
      </Button>
      <Table columns={columns} dataSource={locations} rowKey="id" />
      <Modal
        title="Add New Location"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item name="name" label="Location Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="address" label="Address" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Location
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default LocationManagement;