import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import vehicleReducer from './slices/vehicleSlice';
import campaignReducer from './slices/campaignSlice';
import locationReducer from './slices/locationSlice';
import vehicleDataReducer from './slices/vehicleDataSlice';

export default configureStore({
  reducer: {
    auth: authReducer,
    vehicles: vehicleReducer,
    campaigns: campaignReducer,
    locations: locationReducer,
    vehicleData: vehicleDataReducer,
  },
});