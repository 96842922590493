import React, { useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { Link, Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkAuth } from './store/slices/authSlice';
import {
  DashboardOutlined,
  CarOutlined,
  PlusOutlined,
  FundOutlined,
  SettingOutlined,
  LoginOutlined,
  UserAddOutlined,
  EnvironmentOutlined
} from '@ant-design/icons';
import DashboardWithAddVehicle from './components/DashboardWithAddVehicle';
import VehicleModeling from './components/VehicleModeling';
import CampaignManagement from './components/CampaignManagement';
import LocationManagement from './components/LocationManagement';
import VehicleManagement from './components/VehicleManagement';
import Login from './components/Login';
import Register from './components/Register';
import VehicleEdit from './components/VehicleEdit';

const { Header, Content, Sider } = Layout;

const App = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => !!state.auth.token);
  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  const sidebarItems = [
    {
      key: '1',
      icon: <DashboardOutlined />,
      label: <Link to="/">Dashboard</Link>,
    },
    {
      key: '2',
      icon: <CarOutlined />,
      label: <Link to="/vehicles">Vehicles</Link>,
    },
    {
      key: '3',
      icon: <PlusOutlined />,
      label: <Link to="/model-vehicle">Add Vehicle</Link>,
    },
    {
      key: '4',
      icon: <FundOutlined />,
      label: <Link to="/campaigns">Campaigns</Link>,
    },
  ];

  if (user?.role === 'admin') {
    sidebarItems.push({
      key: '5',
      icon: <EnvironmentOutlined />,
      label: <Link to="/locations">Locations</Link>,
    });
  }

  const topMenuItems = isAuthenticated
    ? [
        {
          key: 'settings',
          icon: <SettingOutlined />,
          label: 'Settings',
        },
      ]
    : [
        {
          key: 'login',
          icon: <LoginOutlined />,
          label: <Link to="/login">Login</Link>,
        },
        {
          key: 'register',
          icon: <UserAddOutlined />,
          label: <Link to="/register">Register</Link>,
        },
      ];

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <div className="demo-logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          items={topMenuItems}
          style={{ flex: 1, justifyContent: 'flex-end' }}
        />
      </Header>
      <Layout>
        {isAuthenticated && (
          <Sider width={200} style={{ background: '#fff' }}>
            <Menu
              mode="inline"
              defaultSelectedKeys={['1']}
              style={{ height: '100%', borderRight: 0 }}
              items={sidebarItems}
            />
          </Sider>
        )}
        <Layout style={{ padding: '0 24px 24px' }}>
          <Content
            style={{
              padding: 24,
              margin: '16px 0',
              minHeight: 280,
              background: '#fff',
              borderRadius: 4,
            }}
          >
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/" element={isAuthenticated ? <DashboardWithAddVehicle /> : <Navigate to="/login" />} />
              <Route
                path="/model-vehicle"
                element={isAuthenticated ? <VehicleModeling /> : <Navigate to="/login" />}
              />
              <Route
                path="/campaigns"
                element={isAuthenticated ? <CampaignManagement /> : <Navigate to="/login" />}
              />
              <Route
                path="/locations"
                element={isAuthenticated && user?.role === 'admin' ? <LocationManagement /> : <Navigate to="/" />}
              />
              <Route path="/vehicles" element={<VehicleManagement />} />
              <Route path="/vehicles/:id" element={<VehicleEdit />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default App;