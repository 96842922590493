import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Upload,
  Card,
  Tag,
  Tabs,
  Space,
  message,
  Popconfirm,
  Typography,
  Row,
  Col,
  Descriptions,
} from 'antd';
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  EditOutlined,
  SaveOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  fetchVehicleById,
  updateVehicle,
  deleteVehicle,
} from '../store/slices/vehicleSlice';
import { fetchMakes, fetchModels } from '../store/slices/vehicleDataSlice';
import axiosInstance from '../utils/axiosInstance';
import DEFAULT_PLACEHOLDER_IMAGE from '../assets/images/car-image-placeholder.png';

const { TextArea } = Input;
const { Option } = Select;
const { Title, Text } = Typography;

const EditableField = ({ value, onSave, inputType = 'text', style = {} }) => {
  const [editing, setEditing] = useState(false);
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleSave = () => {
    onSave(localValue);
    setEditing(false);
  };

  const commonStyle = {
    minHeight: '32px',
    padding: '4px 11px',
    lineHeight: '1.5715',
    ...style,
  };

  if (editing) {
    const InputComponent = inputType === 'number' ? InputNumber : Input;
    return (
      <InputComponent
        value={localValue}
        onChange={(e) =>
          setLocalValue(inputType === 'number' ? e : e.target.value)
        }
        onPressEnter={handleSave}
        onBlur={handleSave}
        autoFocus
        style={commonStyle}
      />
    );
  }

  return (
    <div
      onClick={() => setEditing(true)}
      style={{ ...commonStyle, cursor: 'pointer', display: 'inline-block' }}
    >
      {localValue}{' '}
      <EditOutlined style={{ fontSize: '12px', marginLeft: '5px' }} />
    </div>
  );
};

const FeatureCategory = ({ category, features, setFeatures }) => {
  const [newFeature, setNewFeature] = useState('');
  const [editingIndex, setEditingIndex] = useState(-1);

  const categoryFeatures = features[category] || [];

  const addFeature = () => {
    if (newFeature.trim()) {
      setFeatures((prev) => ({
        ...prev,
        [category]: [
          ...(prev[category] || []),
          { name: newFeature.trim(), order: (prev[category] || []).length },
        ],
      }));
      setNewFeature('');
    }
  };

  const removeFeature = (index) => {
    setFeatures((prev) => ({
      ...prev,
      [category]: prev[category]
        .filter((_, i) => i !== index)
        .map((feature, i) => ({ ...feature, order: i })),
    }));
  };

  const editFeature = (index, newName) => {
    setFeatures((prev) => ({
      ...prev,
      [category]: prev[category].map((feature, i) =>
        i === index ? { ...feature, name: newName } : feature
      ),
    }));
  };

  const moveFeature = (index, direction) => {
    setFeatures((prev) => {
      const newFeatures = [...prev[category]];
      const [movedFeature] = newFeatures.splice(index, 1);
      newFeatures.splice(index + direction, 0, movedFeature);
      return {
        ...prev,
        [category]: newFeatures.map((feature, i) => ({ ...feature, order: i })),
      };
    });
  };

  return (
    <div style={{ marginBottom: 16 }}>
      <h3>{category}</h3>
      <Space direction="vertical" style={{ width: '100%' }}>
        {categoryFeatures.map((feature, index) => (
          <Tag
            key={index}
            closable
            onClose={() => removeFeature(index)}
            style={{ userSelect: 'none' }}
          >
            {editingIndex === index ? (
              <Input
                size="small"
                value={feature.name}
                onChange={(e) => editFeature(index, e.target.value)}
                onPressEnter={() => setEditingIndex(-1)}
                onBlur={() => setEditingIndex(-1)}
                style={{ width: 100 }}
                autoFocus
              />
            ) : (
              <span
                onClick={() => setEditingIndex(index)}
                style={{ cursor: 'text', marginRight: 8 }}
              >
                {feature.name}
              </span>
            )}
            <Space size="small">
              <Button
                type="text"
                size="small"
                icon={<ArrowUpOutlined />}
                onClick={() => moveFeature(index, -1)}
                disabled={index === 0}
              />
              <Button
                type="text"
                size="small"
                icon={<ArrowDownOutlined />}
                onClick={() => moveFeature(index, 1)}
                disabled={index === categoryFeatures.length - 1}
              />
            </Space>
          </Tag>
        ))}
        <Space>
          <Input
            value={newFeature}
            onChange={(e) => setNewFeature(e.target.value)}
            onPressEnter={addFeature}
            placeholder="New feature"
          />
          <Button icon={<PlusOutlined />} onClick={addFeature}>
            Add
          </Button>
        </Space>
      </Space>
    </div>
  );
};

const TechnicalInformation = ({ technicalInfo, setTechnicalInfo }) => {
  const [newAttribute, setNewAttribute] = useState({
    key: '',
    attribute: '',
    value: '',
  });

  const addAttribute = () => {
    if (newAttribute.attribute.trim() && newAttribute.value.trim()) {
      setTechnicalInfo((prev) => [
        ...prev,
        { ...newAttribute, key: Date.now().toString() },
      ]);
      setNewAttribute({ key: '', attribute: '', value: '' });
    }
  };

  const removeAttribute = (key) => {
    setTechnicalInfo((prev) => prev.filter((item) => item.key !== key));
  };

  const editAttribute = (key, field, value) => {
    setTechnicalInfo((prev) =>
      prev.map((item) =>
        item.key === key ? { ...item, [field]: value } : item
      )
    );
  };

  const moveAttribute = (index, direction) => {
    setTechnicalInfo((prev) => {
      const newInfo = [...prev];
      const [movedItem] = newInfo.splice(index, 1);
      newInfo.splice(index + direction, 0, movedItem);
      return newInfo;
    });
  };

  return (
    <div>
      {technicalInfo.map((item, index) => (
        <div key={item.key} style={{ marginBottom: 8 }}>
          <Space>
            <EditableField
              value={item.attribute}
              onSave={(value) => editAttribute(item.key, 'attribute', value)}
              style={{ width: 150 }}
            />
            <EditableField
              value={item.value}
              onSave={(value) => editAttribute(item.key, 'value', value)}
              style={{ width: 150 }}
            />
            <Button
              icon={<ArrowUpOutlined />}
              onClick={() => moveAttribute(index, -1)}
              disabled={index === 0}
            />
            <Button
              icon={<ArrowDownOutlined />}
              onClick={() => moveAttribute(index, 1)}
              disabled={index === technicalInfo.length - 1}
            />
            <Button
              icon={<DeleteOutlined />}
              onClick={() => removeAttribute(item.key)}
            />
          </Space>
        </div>
      ))}
      <Space style={{ marginTop: 16 }}>
        <Input
          placeholder="Attribute"
          value={newAttribute.attribute}
          onChange={(e) =>
            setNewAttribute((prev) => ({ ...prev, attribute: e.target.value }))
          }
        />
        <Input
          placeholder="Value"
          value={newAttribute.value}
          onChange={(e) =>
            setNewAttribute((prev) => ({ ...prev, value: e.target.value }))
          }
        />
        <Button icon={<PlusOutlined />} onClick={addAttribute}>
          Add
        </Button>
      </Space>
    </div>
  );
};

const VehicleEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [vehicleState, setVehicleState] = useState({});
  const vehicle = useSelector((state) => state.vehicles.currentVehicle);
  const [loading, setLoading] = useState(true);

  const defaultFeatures = {
    Safety: [],
    'Interior and Comforts': [],
    Electronics: [],
    Other: [],
  };

  const defaultTechnicalInfo = [
    { key: '1', attribute: 'Power', value: '0 kW' },
    { key: '2', attribute: 'Torque', value: '0 Nm' },
    { key: '3', attribute: 'Battery Capacity', value: '0 kWh' },
    { key: '4', attribute: 'Acceleration (0-100 km/h)', value: '0 s' },
    { key: '5', attribute: 'Range', value: '0 km' },
    { key: '6', attribute: 'Curb Weight', value: '0 kg' },
  ];

  const [features, setFeatures] = useState(defaultFeatures);
  const [technicalInfo, setTechnicalInfo] = useState(defaultTechnicalInfo);
  const [selectedMake, setSelectedMake] = useState('');
  const makes = useSelector((state) => state.vehicleData?.makes || []);
  const models = useSelector((state) => state.vehicleData?.models || []);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    dispatch(fetchVehicleById(id)).then(() => setLoading(false));
    dispatch(fetchMakes());
  }, [dispatch, id]);

  useEffect(() => {
    if (vehicle) {
      // Parse features
      let parsedFeatures = defaultFeatures;

      if (vehicle.features) {
        if (typeof vehicle.features === 'string') {
          try {
            parsedFeatures = JSON.parse(vehicle.features);
          } catch (error) {
            console.error('Error parsing vehicle.features:', error);
            parsedFeatures = defaultFeatures;
          }
        } else {
          parsedFeatures = vehicle.features;
        }
      }

      // Ensure category values are arrays
      Object.keys(parsedFeatures).forEach((category) => {
        if (!Array.isArray(parsedFeatures[category])) {
          parsedFeatures[category] = [];
        }
      });

      setFeatures(parsedFeatures);

      // Parse technicalInfo
      let parsedTechnicalInfo = defaultTechnicalInfo;

      if (vehicle.technicalInfo) {
        if (typeof vehicle.technicalInfo === 'string') {
          try {
            parsedTechnicalInfo = JSON.parse(vehicle.technicalInfo);
          } catch (error) {
            console.error('Error parsing vehicle.technicalInfo:', error);
            parsedTechnicalInfo = [];
          }
        } else {
          parsedTechnicalInfo = vehicle.technicalInfo;
        }
      }

      setTechnicalInfo(parsedTechnicalInfo);

      // Set form fields
      form.setFieldsValue({
        ...vehicle,
        make: vehicle.make?.name || vehicle.make || '',
        model: vehicle.model?.name || vehicle.model || '',
      });

      // Set vehicle state
      setVehicleState({
        ...vehicle,
        make: vehicle.make?.name || vehicle.make || '',
        model: vehicle.model?.name || vehicle.model || '',
      });

      // Set selected make
      if (vehicle.make?.name || vehicle.make) {
        setSelectedMake(vehicle.make?.name || vehicle.make);
      }

      // Handle images
      if (vehicle.imageObjects) {
        setFileList(
          vehicle.imageObjects.map((image) => ({
            uid: image.id,
            name: image.url.split('/').pop(),
            status: 'done',
            url: `${process.env.REACT_APP_API_URL}${image.url}`,
          }))
        );
      }
    }
  }, [vehicle, form]);

  const handleUpload = async ({ file, onSuccess, onError, onProgress }) => {
    const formData = new FormData();
    formData.append('images', file);
  
    try {
      const response = await axiosInstance.post(
        `/api/vehicles/${id}/images`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: ({ total, loaded }) => {
            onProgress({ percent: Math.round((loaded / total) * 100) }, file);
          },
        }
      );
  
      // Pass the necessary data to onSuccess
      onSuccess({
        id: response.data.id,
        url: `${process.env.REACT_APP_API_URL}${response.data.url}`,
      });
  
      message.success('Image uploaded successfully');
    } catch (error) {
      onError({ error });
      message.error('Failed to upload image');
    }
  };
  

  const handleRemove = async (file) => {
    try {
      await axiosInstance.delete(`/api/vehicles/${id}/images/${file.uid}`);
      setFileList((prevFileList) =>
        prevFileList.filter((item) => item.uid !== file.uid)
      );
      message.success('Image deleted successfully');
    } catch (error) {
      message.error('Failed to delete image');
    }
  };

  const handleMakeChange = (value) => {
    setSelectedMake(value);
    form.setFieldsValue({ model: undefined });
    setVehicleState((prev) => ({ ...prev, make: value, model: undefined }));
    if (value) {
      dispatch(fetchModels(value));
    }
  };

  const onFinish = async (values) => {
    try {
      const formData = new FormData();

      Object.keys(values).forEach((key) => {
        if (key !== 'images') {
          formData.append(key, values[key]);
        }
      });

      Object.keys(vehicleState).forEach((key) => {
        if (
          !formData.has(key) &&
          vehicleState[key] !== undefined &&
          vehicleState[key] !== null
        ) {
          formData.append(key, vehicleState[key]);
        }
      });

      // Serialize features and technicalInfo before appending to formData
      formData.set('features', JSON.stringify(features));
      formData.set('technicalInfo', JSON.stringify(technicalInfo));

      await dispatch(updateVehicle({ id, formData })).unwrap();
      message.success('Vehicle updated successfully');

      // Refetch the vehicle data to ensure we have the latest state
      await dispatch(fetchVehicleById(id)).unwrap();
    } catch (error) {
      message.error('Failed to update vehicle: ' + error.message);
    }
  };

  const handleDelete = async () => {
    try {
      await dispatch(deleteVehicle(id)).unwrap();
      message.success('Vehicle deleted successfully');
      navigate('/vehicles');
    } catch (error) {
      message.error('Failed to delete vehicle: ' + error.message);
    }
  };

  const handleFieldUpdate = (field, value) => {
    form.setFieldsValue({ [field]: value });
    setVehicleState((prev) => ({ ...prev, [field]: value }));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleValuesChange = (changedValues) => {
    setVehicleState((prev) => ({ ...prev, ...changedValues }));
  };

  const handleChange = ({ fileList }) => {
    const newFileList = fileList.map((file) => {
      if (file.response) {
        // Update the file object with the response data
        return {
          ...file,
          uid: file.response.id,
          url: file.response.url,
          status: 'done',
        };
      }
      return file;
    });
    setFileList(newFileList);
  };  

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onValuesChange={handleValuesChange}
      style={{ maxWidth: '1000px', margin: '0 auto' }}
    >
      <Card>
        <Row gutter={16} align="middle">
          <Col span={16}>
            <Title level={2}>
              <EditableField
                value={vehicleState.registrationNumber || ''}
                onSave={(value) => handleFieldUpdate('registrationNumber', value)}
                style={{ fontSize: '30px', fontWeight: 'bold' }}
              />
            </Title>
            <Text type="secondary">
              Last updated:{' '}
              {vehicleState.updatedAt
                ? new Date(vehicleState.updatedAt).toLocaleDateString()
                : 'N/A'}
            </Text>
            <Title level={3}>
              <EditableField
                value={`${vehicleState.make || ''} ${vehicleState.model || ''}`}
                onSave={(value) => {
                  const [make, ...modelParts] = value.split(' ');
                  handleFieldUpdate('make', make);
                  handleFieldUpdate('model', modelParts.join(' '));
                }}
                style={{ fontSize: '24px', fontWeight: 'bold' }}
              />
            </Title>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Title level={2} style={{ color: '#1890ff' }}>
              €{' '}
              <EditableField
                value={vehicleState.price || 0}
                onSave={(value) => handleFieldUpdate('price', value)}
                inputType="number"
                style={{ fontSize: '30px', fontWeight: 'bold', color: '#1890ff' }}
              />
            </Title>
          </Col>
        </Row>
      </Card>

      <Card style={{ marginTop: 16 }}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="make" label="Make" rules={[{ required: true }]}>
              <Select onChange={handleMakeChange} allowClear>
                {makes.map((make) => (
                  <Option key={make} value={make}>
                    {make}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="model" label="Model" rules={[{ required: true }]}>
              <Select
                disabled={!selectedMake}
                allowClear
                onChange={(value) => handleFieldUpdate('model', value)}
              >
                {models.map((model) => (
                  <Option key={model} value={model}>
                    {model}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="powertrainType"
              label="Powertrain Type"
              rules={[{ required: true }]}
            >
              <Select onChange={(value) => handleFieldUpdate('powertrainType', value)}>
                <Option value="gasoline">Gasoline</Option>
                <Option value="diesel">Diesel</Option>
                <Option value="electric">Electric</Option>
                <Option value="hybrid">Hybrid</Option>
                <Option value="plugin_hybrid">Plug-in Hybrid</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Title level={4}>
          <EditableField
            value={vehicleState.description || ''}
            onSave={(value) => handleFieldUpdate('description', value)}
            style={{ fontSize: '18px', fontWeight: 'normal' }}
          />
        </Title>
        <Row gutter={16}>
          <Col span={16}>
            <img
              src={
                fileList.length > 0 ? fileList[0].url : DEFAULT_PLACEHOLDER_IMAGE
              }
              alt="Main car"
              style={{ width: '100%', height: 'auto' }}
            />
          </Col>
          <Col span={8}>
            <Form.Item name="images" label="Images">
              <Upload
                listType="picture-card"
                fileList={fileList}
                customRequest={handleUpload}
                onRemove={handleRemove}
                onChange={handleChange}
                accept="image/*"
              >
                {fileList.length >= 10 ? null : (
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Card style={{ marginTop: 16 }}>
        <Descriptions title="Car Details" bordered>
          <Descriptions.Item label="License Plate">
            <EditableField
              value={vehicleState.registrationNumber || ''}
              onSave={(value) => handleFieldUpdate('registrationNumber', value)}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Location">
            <EditableField
              value={vehicleState.location?.name || ''}
              onSave={(value) => handleFieldUpdate('location', { name: value })}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Mileage">
            <EditableField
              value={vehicleState.mileage || 0}
              onSave={(value) => handleFieldUpdate('mileage', value)}
              inputType="number"
            />{' '}
            km
          </Descriptions.Item>
          <Descriptions.Item label="VIN">
            <EditableField
              value={vehicleState.vin || ''}
              onSave={(value) => handleFieldUpdate('vin', value)}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Year">
            <EditableField
              value={vehicleState.year || ''}
              onSave={(value) => handleFieldUpdate('year', value)}
              inputType="number"
            />
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            <Select
              value={vehicleState.status}
              onChange={(value) => handleFieldUpdate('status', value)}
              style={{ width: '100%' }}
            >
              <Option value="for_sale">For Sale</Option>
              <Option value="sold">Sold</Option>
            </Select>
          </Descriptions.Item>
          <Descriptions.Item label="Vehicle Type">
            <EditableField
              value={vehicleState.vehicleType || ''}
              onSave={(value) => handleFieldUpdate('vehicleType', value)}
            />
          </Descriptions.Item>
        </Descriptions>
      </Card>

      <Card style={{ marginTop: 16 }}>
        <Tabs
          items={[
            {
              key: '1',
              label: 'Features',
              children: (
                <>
                  {Object.keys(features).map((category) => (
                    <FeatureCategory
                      key={category}
                      category={category}
                      features={features}
                      setFeatures={setFeatures}
                    />
                  ))}
                  <Button
                    type="dashed"
                    onClick={() =>
                      setFeatures((prev) => ({ ...prev, ['New Category']: [] }))
                    }
                    style={{ marginTop: 16 }}
                  >
                    Add New Category
                  </Button>
                </>
              ),
            },
            {
              key: '2',
              label: 'Technical Information',
              children: (
                <TechnicalInformation
                  technicalInfo={technicalInfo}
                  setTechnicalInfo={setTechnicalInfo}
                />
              ),
            },
            {
              key: '3',
              label: 'Additional Notes',
              children: (
                <Form.Item name="notes">
                  <TextArea
                    rows={6}
                    placeholder="Add any additional notes here..."
                    onChange={(e) => handleFieldUpdate('notes', e.target.value)}
                  />
                </Form.Item>
              ),
            },
          ]}
        />
      </Card>

      <Space style={{ marginTop: 16 }}>
        <Button type="primary" icon={<SaveOutlined />} htmlType="submit">
          Save Changes
        </Button>
        <Popconfirm
          title="Are you sure you want to delete this vehicle?"
          onConfirm={handleDelete}
          okText="Yes"
          cancelText="No"
        >
          <Button danger icon={<DeleteOutlined />}>
            Delete Vehicle
          </Button>
        </Popconfirm>
      </Space>
    </Form>
  );
};

export default VehicleEdit;
