import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';

export const fetchVehicles = createAsyncThunk(
  'vehicles/fetchVehicles',
  async (filters = {}) => {
    const response = await axiosInstance.get('/api/vehicles', { params: filters });
    return response.data;
  }
);

export const createVehicle = createAsyncThunk(
  'vehicles/createVehicle',
  async (vehicleData) => {
    // Ensure all required fields are present and not null/undefined
    const requiredFields = ['registrationNumber', 'mileage', 'vehicleType'];
    requiredFields.forEach(field => {
      if (vehicleData[field] == null) {
        throw new Error(`${field} is required`);
      }
    });

    const response = await axiosInstance.post('/api/vehicles', vehicleData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  }
);

export const updateVehicle = createAsyncThunk(
  'vehicles/updateVehicle',
  async ({ id, formData }) => {
    const response = await axiosInstance.put(`/api/vehicles/${id}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  }
);

export const deleteVehicle = createAsyncThunk(
  'vehicles/deleteVehicle',
  async (id) => {
    await axiosInstance.delete(`/api/vehicles/${id}`);
    return id;
  }
);

export const fetchVehicleById = createAsyncThunk(
  'vehicles/fetchVehicleById',
  async (id) => {
    const response = await axiosInstance.get(`/api/vehicles/${id}`);
    const vehicleData = response.data;

    // Parse features and technicalInfo if they are strings
    if (typeof vehicleData.features === 'string') {
      try {
        vehicleData.features = JSON.parse(vehicleData.features);
      } catch (error) {
        console.error('Error parsing features:', error);
        vehicleData.features = {};
      }
    }

    if (typeof vehicleData.technicalInfo === 'string') {
      try {
        vehicleData.technicalInfo = JSON.parse(vehicleData.technicalInfo);
      } catch (error) {
        console.error('Error parsing technicalInfo:', error);
        vehicleData.technicalInfo = [];
      }
    }

    return vehicleData;
  }
);

export const fetchDashboardStats = createAsyncThunk(
  'vehicles/fetchDashboardStats',
  async () => {
    const response = await axiosInstance.get('/api/vehicles/stats');
    return response.data;
  }
);

const vehicleSlice = createSlice({
  name: 'vehicles',
  initialState: {
    list: [],
    currentVehicle: null,
    dashboardStats: {
      totalCars: 0,
      carsForSale: 0,
      soldCars: 0,
      campaignPerformance: [],
    },
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVehicles.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchVehicles.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.list = action.payload;
      })
      .addCase(fetchVehicles.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createVehicle.fulfilled, (state, action) => {
        state.list.push(action.payload);
      })
      .addCase(updateVehicle.fulfilled, (state, action) => {
        // Update the vehicle in the list
        const index = state.list.findIndex(vehicle => vehicle.id === action.payload.id);
        if (index !== -1) {
          state.list[index] = action.payload;
        }
        // Also update currentVehicle
        if (state.currentVehicle && state.currentVehicle.id === action.payload.id) {
          state.currentVehicle = action.payload;
        }
      })
      .addCase(deleteVehicle.fulfilled, (state, action) => {
        state.list = state.list.filter(vehicle => vehicle.id !== action.payload);
        if (state.currentVehicle && state.currentVehicle.id === action.payload) {
          state.currentVehicle = null;
        }
      })
      .addCase(fetchVehicleById.fulfilled, (state, action) => {
        state.currentVehicle = action.payload;
      })
      .addCase(fetchDashboardStats.fulfilled, (state, action) => {
        state.dashboardStats = action.payload;
      });
  },
});

export default vehicleSlice.reducer;
