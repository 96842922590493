import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Select, InputNumber, message, Steps } from 'antd';
import { createVehicle } from '../store/slices/vehicleSlice';
import { fetchLocations } from '../store/slices/locationSlice';

const { Option } = Select;
const { Step } = Steps;

const VehicleModeling = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  const locations = useSelector(state => state.locations.list);

  useEffect(() => {
    dispatch(fetchLocations());
  }, [dispatch]);

  const onFinish = async (values) => {
    const vehicleData = {
      registrationNumber: values.registrationNumber,
      make: values.make,
      model: values.model,
      year: values.year,
      price: values.price,
      LocationId: values.LocationId,
      vin: values.vin,
      status: values.status || 'for_sale',
      technicalSpecs: {
        mileage: values.mileage,
        fuelType: values.fuelType,
        transmission: values.transmission,
      },
      condition: {
        exteriorGrade: values.exteriorGrade,
        interiorGrade: values.interiorGrade,
      },
    };

    try {
      await dispatch(createVehicle(vehicleData)).unwrap();
      message.success('Vehicle created successfully');
      form.resetFields();
      setCurrentStep(0);
    } catch (error) {
      message.error('Failed to create vehicle: ' + (error.message || 'Unknown error'));
    }
  };

  const steps = [
    {
      title: 'Basic Info',
      content: (
        <>
          <Form.Item name="make" label="Make" rules={[{ required: true, message: 'Please input the make' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="model" label="Model" rules={[{ required: true, message: 'Please input the model' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="year" label="Year" rules={[{ required: true, message: 'Please input the year' }]}>
            <InputNumber min={1900} max={new Date().getFullYear() + 1} />
          </Form.Item>
          <Form.Item name="registrationNumber" label="Registration Number" rules={[{ required: true, message: 'Please input the registration number' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="price" label="Price" rules={[{ required: true, message: 'Please input the price' }]}>
            <InputNumber
              min={0}
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item name="LocationId" label="Location" rules={[{ required: true, message: 'Please select a location' }]}>
            <Select>
              {locations.map(location => (
                <Option key={location.id} value={location.id}>{location.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </>
      ),
    },
    {
      title: 'Additional Info',
      content: (
        <>
          <Form.Item name="vin" label="VIN">
            <Input />
          </Form.Item>
          <Form.Item name="status" label="Status" initialValue="for_sale">
            <Select>
              <Option value="for_sale">For Sale</Option>
              <Option value="sold">Sold</Option>
            </Select>
          </Form.Item>
          <Form.Item name="mileage" label="Mileage">
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item name="fuelType" label="Fuel Type">
            <Select>
              <Option value="petrol">Petrol</Option>
              <Option value="diesel">Diesel</Option>
              <Option value="electric">Electric</Option>
              <Option value="hybrid">Hybrid</Option>
            </Select>
          </Form.Item>
          <Form.Item name="transmission" label="Transmission">
            <Select>
              <Option value="manual">Manual</Option>
              <Option value="automatic">Automatic</Option>
            </Select>
          </Form.Item>
          <Form.Item name="exteriorGrade" label="Exterior Condition">
            <Select>
              <Option value="excellent">Excellent</Option>
              <Option value="good">Good</Option>
              <Option value="fair">Fair</Option>
              <Option value="poor">Poor</Option>
            </Select>
          </Form.Item>
          <Form.Item name="interiorGrade" label="Interior Condition">
            <Select>
              <Option value="excellent">Excellent</Option>
              <Option value="good">Good</Option>
              <Option value="fair">Fair</Option>
              <Option value="poor">Poor</Option>
            </Select>
          </Form.Item>
        </>
      ),
    },
  ];

  const next = () => {
    form.validateFields().then(() => {
      setCurrentStep(currentStep + 1);
    });
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <div>
      <h1>Vehicle Modeling</h1>
      <Steps current={currentStep}>
        {steps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <Form form={form} onFinish={onFinish} layout="vertical" style={{ marginTop: 24 }}>
        <div>{steps[currentStep].content}</div>
        <div style={{ marginTop: 24 }}>
          {currentStep > 0 && (
            <Button style={{ marginRight: 8 }} onClick={prev}>
              Previous
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button type="primary" onClick={next}>
              Next
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default VehicleModeling;